@import 'variables';

.sidebar {
    position: sticky;
    top: 0;
    width: 260px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 30px;
    background-color: $white-color;
    box-shadow: 4px 0 4px 0 rgba(43, 45, 55, 0.01);

    // @media (min-width: 992px) {
    //     padding: 48px 30px;
    // }

    .sidebar-logo {
        width: 150px;
        margin: 2px 0 32px;
        display: block;
    }

    .nav-pills {
        margin: 0 -15px;

        .nav-link {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 15px 24px 15px 0;
            border-radius: 12px;

            i {
                width: 56px;
                text-align: center;
                font-size: 20px;
            }

            .fa-chevron-down {
                width: auto;
                font-size: 14px;
                display: flex;
                margin-left: auto;
                transform-origin: center center;
            }

            &[aria-expanded=true] {
                .fa-chevron-down {
                    transform: rotate(180deg);
                }
            }

            &:not(.active) {
                i {
                    color: $primary-color;
                }

                &:hover {
                    color: $primary-color;
                    i:not(.fa-chevron-down) {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background: linear-gradient(336deg, #fa70a2, #f49f92);
        box-shadow: 2px 4px 16px #ff78755e;
        font-weight: bold;
    }
}


.sidebar-footer {
    font-size: 16px;
    a {
        color: $primary-color;
        letter-spacing: 3px;

        &:hover {
            color: $orange-color;
        }
    }
}

.nav-sub-item {
    padding-left: 56px;

    .nav-sub-link {
        display: block;
        margin: 6px 0;
        color: $grey-color-3;
        width: 100%;
        text-decoration: none;

        &.active {
            color: $primary-color;
            font-weight: bold;
        }

        &:hover {
            color: $primary-color;
        }
    }
}